// InputForm.js
import React from 'react';

const InputForm = ({ inputValue, setInputValue, handleSend, inputError }) => {
  return (
    <form onSubmit={handleSend} className='custom-message-input-container'>
      <textarea
        className='custom-message-input'
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyPress={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend(e);
          }
        }}
        placeholder='Type your message...'
        rows='1'
      />
      {inputError && <div className="input-error">{inputError}</div>}

      <button className='custom-message-send-btn' type='submit'>
        Send
      </button>
    </form>
  );
};

export default InputForm;

