
import React from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "./../styles/Blog.css";

const Blog = ({ articles }) => {
  const { index } = useParams();
  const article = articles[index];

  return (
    <>
      <div className="blog-page">
        <Header />
        <div className="container">
          <div className="blog-article">
            <h2 className="blog-title">{article.title}</h2>
            <div className="author-date">
              <span className="author">{article.author}</span> |{" "}
              <span className="date">{article.date}</span>
            </div>
            <div className="blog-content">{article.content}</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
