import React, { useState, useEffect, useCallback } from 'react'
import './../styles/GoalsManagement.css'
import Header from './Header'
import Footer from './Footer'
import { toast } from 'react-toastify'
import Joyride, { STATUS } from 'react-joyride'
import { detectOverflow } from '@popperjs/core'

import {
  createGoal,
  fetchGoals,
  updateGoal,
  deleteGoal,
  createSubTask,
  fetchSubTasks,
  updateSubTask,
  deleteSubTask
} from './../helpers/api'

import { auth } from './../config/firebaseConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const GoalsManagement = () => {
  const [goals, setGoals] = useState([])
  const [userId, setUserId] = useState(null)
  const [subTasks, setSubTasks] = useState([])
  const [goalInputError, setGoalInputError] = useState(false)
  const [subTaskInputError, setSubTaskInputError] = useState(false)
  const [joyrideRun, setJoyrideRun] = useState(false)

  const fetchGoalsData = async () => {
    if (userId) {
      const fetchedGoals = await fetchGoals(userId)
      setGoals(fetchedGoals)
    }
  }

  const addGoal = useCallback(
    async text => {
      const currentDate = new Date()
      const newGoal = {
        userId,
        id: currentDate.getTime().toString(),
        text,
        term: '',
        completed: false,
        createdAt: currentDate.toISOString() // Add createdAt here
      }
      await createGoal(newGoal) // Pass the entire goal object, including the userId and createdAt
      setGoals([...goals, newGoal])
    },
    [goals, userId]
  )

  const handleUpdateGoal = useCallback(
    async (id, key, value) => {
      await updateGoal(userId, id, {
        ...goals.find(goal => goal.id === id),
        [key]: value
      })
      setGoals(prevGoals =>
        prevGoals.map(goal =>
          goal.id === id ? { ...goal, [key]: value } : goal
        )
      )
    },
    [goals, userId]
  )

  const removeGoal = useCallback(
    async id => {
      await deleteGoal(userId, id)
      setGoals(goals.filter(goal => goal.id !== id))
    },
    [goals, userId]
  )

  const removeCompletedGoal = useCallback(
    async id => {
      await deleteGoal(userId, id)
      setGoals(goals.filter(goal => goal.id !== id))
    },
    [goals, userId]
  )

  const toggleComplete = useCallback(
    async id => {
      const goal = goals.find(goal => goal.id === id)
      goal.completed = !goal.completed
      await updateGoal(userId, id, goal)
      setGoals(goals.map(goal => (goal.id === id ? goal : goal)))
    },
    [goals, userId]
  )

  const addGoalInput = e => {
    if (e.key === 'Enter') {
      if (e.target.value.trim() === '') {
        toast.error('Goal is required')
        setGoalInputError(true)
      } else {
        addGoal(e.target.value)
        e.target.value = ''
        setGoalInputError(false)
      }
    }
  }

  const activeGoals = goals.filter(goal => !goal.completed)
  const completedGoals = goals.filter(goal => goal.completed)

  useEffect(() => {
    fetchGoalsData()
  }, [userId])

  useEffect(() => {
    fetchSubTasksData()
  }, [userId, goals])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid)
      } else {
        setUserId(null)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  // sub-tasks

  const addSubTask = useCallback(
    async (goalId, text) => {
      if (text.trim() === '') {
        setSubTaskInputError(true)
        toast.error('Sub-task is required')
      } else {
        const newSubTask = {
          userId,
          goalId,
          id: Date.now(),
          text,
          completed: false
        }
        await createSubTask(newSubTask)
        setSubTasks([...subTasks, newSubTask])
        setSubTaskInputError(false)
      }
    },
    [subTasks, userId]
  )

  const removeSubTask = useCallback(
    async (goalId, subtaskId) => {
      await deleteSubTask(userId, goalId, subtaskId)
      setSubTasks(subTasks.filter(subTask => subTask.id !== subtaskId))
    },
    [subTasks, userId]
  )

  const handleUpdateSubTask = useCallback(
    async (id, key, value) => {
      await updateSubTask(userId, id, {
        ...subTasks.find(subTask => subTask.id === id),
        [key]: value
      })
      setSubTasks(prevSubTasks =>
        prevSubTasks.map(subTask =>
          subTask.id === id ? { ...subTask, [key]: value } : subTask
        )
      )
    },
    [subTasks, userId]
  )

  const fetchSubTasksData = async () => {
    if (userId && goals) {
      const fetchedSubTasks = []

      for (const goal of goals) {
        const goalSubTasks = await fetchSubTasks(userId, goal.id)
        fetchedSubTasks.push(...goalSubTasks)
      }

      setSubTasks(fetchedSubTasks)
    }
  }

  // Define the tutorial steps
  const tutorialSteps = [
    {
      target: '.goals-list',
      content:
        'Here you can view and manage your goals. Click on a goal to edit or mark it as completed.'
    },
    {
      target: '.goals-list input[type="text"]',
      content:
        'To add a new goal, type your goal in the input field and press the return key.'
    },
    {
      target: '.subtasks-list',
      content:
        'You can also manage subtasks for each goal. Type a subtask in the input field and press the return key to add it.'
    },
    {
      target: '.goal-item-buttons .complete-goal',
      content:
        'Use the "Mark as Complete" button to mark a goal as completed or incomplete.'
    },
    {
      target: '.goal-item-buttons .remove-goal',
      content: 'To remove a goal, click the "x" button.'
    },
    {
      target: '.completed-goals-list',
      content:
        'Here you can view your completed goals. They will be listed in this section once marked as complete.'
    },
    {
      target: '.completed-goal-item .remove-goal',
      content:
        'You can remove completed goals by clicking the "x" button next to each goal.'
    }
  ]

  const handleJoyrideCallback = data => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideRun(false);
      localStorage.setItem('goalManagementTutorialSeen', 'true');
    }
  };
  

  useEffect(() => {
    if (userId) {
      const tutorialSeen = localStorage.getItem('goalManagementTutorialSeen');
      if (!tutorialSeen) {
        setJoyrideRun(true);
      } else {
        setJoyrideRun(false);
      }
    }
  }, [userId]);
  

  return (
    <div className='goals-management-page'>
      <Header />
      <Joyride
        steps={tutorialSteps}
        run={joyrideRun}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#82c0ba',
            zIndex: 10000
          }
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                padding: 8,
                boundary: 'clippingParents',
                altBoundary: true,
                fallbackPlacements: ['top', 'bottom', 'right', 'left'],
                allowedAutoPlacements: ['top', 'bottom', 'right', 'left'],
                mainAxis: true,
                altAxis: true,
                checkVariation: true,
                detectOverflow: detectOverflow
              }
            }
          ]
        }}
      />
      <div className='goals-management-container'>
        <div className='goals-management'>
          <h2>Goals Management</h2>
          <ul className='goals-list'>
            {activeGoals.map(goal => (
              <React.Fragment key={goal.id}>
                <li className='goal-item goal-item-wrapper'>
                  <input
                    type='text'
                    value={goal.text}
                    onChange={e =>
                      handleUpdateGoal(goal.id, 'text', e.target.value)
                    }
                  />
                  <select
                    value={goal.term}
                    onChange={e =>
                      handleUpdateGoal(goal.id, 'term', e.target.value)
                    }
                  >
                    <option value=''>Select term</option>
                    <option value='short'>Short term</option>
                    <option value='long'>Long term</option>
                  </select>
                  <div className='goal-item-buttons'>
                    <button
                      className='complete-goal'
                      onClick={() => toggleComplete(goal.id)}
                    >
                      {goal.completed
                        ? 'Mark as Incomplete'
                        : 'Mark as Complete'}
                    </button>
                    <button onClick={() => removeGoal(goal.id)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </li>
                <ul className='subtasks-list'>
                  {subTasks
                    .filter(subTask => subTask.goalId === goal.id)
                    .map(subTask => (
                      <li key={subTask.id} className='subtask-item'>
                        <input
                          type='text'
                          value={subTask.text}
                          onChange={e =>
                            handleUpdateSubTask(
                              subTask.id,
                              'text',
                              e.target.value
                            )
                          }
                        />
                        <button
                          onClick={() => removeSubTask(goal.id, subTask.id)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </li>
                    ))}
                  <li className='subtask-item'>
                    <input
                      type='text'
                      placeholder='Enter a new subtask...'
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          addSubTask(goal.id, e.target.value)
                          e.target.value = ''
                        }
                      }}
                      className={subTaskInputError ? 'input-error' : ''}
                    />
                  </li>
                </ul>
              </React.Fragment>
            ))}
            <li className='goal-item'>
              <input
                type='text'
                placeholder='Enter a new goal...'
                onKeyDown={addGoalInput}
                className={goalInputError ? 'input-error' : ''}
              />
            </li>
          </ul>
          <h3>Completed Goals</h3>
          <ul className='completed-goals-list'>
            {completedGoals.map(goal => (
              <li key={goal.id} className='completed-goal-item'>
                <span className='completed-goal-text'>{goal.text}</span>
                <button
                  className='remove-goal'
                  onClick={() => removeCompletedGoal(goal.id)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default GoalsManagement
