import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./../styles/Contact.css";
import emailjs from "emailjs-com";

const Contact = () => {
  const onSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dox60a7",
        "template_so7t5ji",
        e.target,
        "0shnKVDHTpxc7yCmi"
      )
      .then(
        (result) => {
          alert("Message sent, thank you!");
        },
        (error) => {
          alert("An error occurred, please try again.");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="specific-contact-page">
        <Header />
        <h1>Contact Us</h1>
        <div className="specific-contact-container">
          <p>If you have any questions or need assistance, please fill out the form below:</p>
          <form onSubmit={onSubmit} className="contact-form">
            <label htmlFor="contact_type">Type of Contact</label>
            <select id="contact_type" name="contact_type" required>
              <option value="business">I have a business-related inquiry</option>
              <option value="support">I'm a user and I need support</option>
              <option value="technical">I have a technical-related inquiry</option>
              <option value="service">I have a question about the service</option>
              <option value="press">I have a press-related inquiry</option>
              {/* Add more options as needed */}
            </select>
            <label htmlFor="first_name">First Name</label>
            <input type="text" id="first_name" name="first_name" required />
            <label htmlFor="last_name">Last Name</label>
            <input type="text" id="last_name" name="last_name" required />
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="message">Your Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>
            <button type="submit" className="submit-button">
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
