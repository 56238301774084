import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDj-XG6fVce0agLYC9RCQBWXpFLZuCz3mM",
  authDomain: "aidacura-e1eec.firebaseapp.com",
  databaseURL: "https://aidacura-e1eec-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aidacura-e1eec",
  storageBucket: "aidacura-e1eec.appspot.com",
  messagingSenderId: "1082802196445",
  appId: "1:1082802196445:web:49c6387024cd16ea3ef034",
  measurementId: "G-JB21HYEFZ1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db};
