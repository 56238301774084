import React from "react";
import { Link } from "react-router-dom";
import "./../styles/News.css";
import Header from "./Header";
import Footer from "./Footer";
import articles from "./../articles.json";

const News = () => {
  return (
    <>
      <div className="news-page">
        <Header />
        <h1>News</h1>
        <div className="container">
          {articles.map((article, index) => (
            <div
              className={`news-article ${index === 0 ? "large-article" : "small-article"}`}
              key={index}
            >
              <h2>{article.title}</h2>
              <p>{article.summary}</p>
              <div className="author">{article.author}</div>
              <div className="date">{article.date}</div>
              <Link
                to={{ pathname: `/news/${index}`, state: { articles } }}
                className="read-more"
              >
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
