import React, { useState } from "react";
import sadFace from "./../assets/sad.png";
import straightFace from "./../assets/straight.png";
import happyFace from "./../assets/happy.png";
import "./../styles/JournalEntry.css";

const moodImages = {
  sad: sadFace,
  neutral: straightFace,
  happy: happyFace,
};

const JournalEntry = ({ entry, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
 
  
  return (
    <div className={`journal-entry ${isOpen ? "open" : ""}`} onClick={toggleOpen}>
      <h3>{`${entry.title}`}<img src={moodImages[entry.mood]} alt={entry.mood} /></h3>
      {isOpen && (
        <>
          <div className="entry-content">
            <h4>Thoughts</h4>
            <p class="entry-text">{entry.thoughts}</p>
            <h4>Feelings</h4>
            <p class="entry-text">{entry.feelings}</p>
          </div>
          <button className="delete-button" onClick={() => onDelete(entry.id)}>
            Delete Entry
          </button>
        </>
      )}
    </div>
  );
};

export default JournalEntry;
