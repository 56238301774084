import React, { useEffect, useState } from "react";
import sadFace from "./../assets/sad.png";
import straightFace from "./../assets/straight.png";
import happyFace from "./../assets/happy.png";
import "./../styles/JournalMoodTracker.css";
import { fetchJournalEntries } from "./../helpers/api";

const moods = [
  { name: "Sad", value: "sad", img: sadFace },
  { name: "Neutral", value: "neutral", img: straightFace },
  { name: "Happy", value: "happy", img: happyFace },
];

const JournalList = ({ userId }) => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const fetchEntries = async () => {
      if (userId) {
        const fetchedEntries = await fetchJournalEntries(userId);
        setEntries(fetchedEntries);
      }
    };

    fetchEntries();
  }, [userId]);

  return (
    <div className="journal-list">
      <ul>
        {entries.slice(0, 4).map((entry, index) => (
          <li key={index} className="journal-item">
            <img
              src={moods.find((mood) => mood.value === entry.mood).img}
              alt="mood indicator"
              className="mood-indicator"
            />
            <span>{entry.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JournalList;

