import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import JournalEntry from './JournalEntry'
import sadFace from './../assets/sad.png'
import straightFace from './../assets/straight.png'
import happyFace from './../assets/happy.png'
import './../styles/JournalMoodTracker.css'
import {
  createJournalEntry,
  fetchJournalEntries,
  deleteJournalEntry
} from './../helpers/api'
import { auth } from './../config/firebaseConfig'
import { toast } from 'react-toastify'
import Joyride, { STATUS } from 'react-joyride'
import { detectOverflow } from '@popperjs/core'

const moods = [
  { name: 'Sad', value: 'sad', img: sadFace },
  { name: 'Neutral', value: 'neutral', img: straightFace },
  { name: 'Happy', value: 'happy', img: happyFace }
]

const JournalMoodTracker = () => {
  const [entries, setEntries] = useState([])
  const [title, setTitle] = useState('')
  const [mood, setMood] = useState(moods[1].value)
  const [thoughts, setThoughts] = useState('')
  const [feelings, setFeelings] = useState('')
  const [userId, setUserId] = useState(null)
  const [errors, setErrors] = useState({})
  const [joyrideRun, setJoyrideRun] = useState(false)

  // create journal
  const handleSubmit = async e => {
    e.preventDefault()

    // Perform validation checks
    const formErrors = {}
    if (title.trim() === '') {
      formErrors.title = 'Title is required'
    }
    if (thoughts.trim() === '') {
      formErrors.thoughts = 'Thoughts are required'
    }
    if (feelings.trim() === '') {
      formErrors.feelings = 'Feelings are required'
    }

    // Show toast errors and return early if there are any validation issues
    if (Object.keys(formErrors).length > 0) {
      for (const error in formErrors) {
        toast.error(formErrors[error])
      }
      return
    }

    // Set errors and return early if there are any validation issues
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }

    const currentDate = new Date()
    const formattedDate = currentDate.toLocaleDateString('en-GB')

    const newEntry = {
      id: currentDate.getTime().toString(),
      userId: userId,
      title: title + ' (' + formattedDate + ')',
      mood,
      thoughts,
      feelings,
      createdAt: new Date().toISOString()
    }

    await createJournalEntry(newEntry)

    // Update the state directly instead of refetching the data
    setEntries(prevEntries => [newEntry, ...prevEntries])

    setTitle('')
    setMood(moods[1].value)
    setThoughts('')
    setFeelings('')
  }

  // handle delete journal
  const handleDelete = async id => {
    await deleteJournalEntry(userId, id) // Pass userId here
    const updatedEntries = await fetchJournalEntries(userId) // Pass userId here
    setEntries(updatedEntries)
  }

  // use effects
  useEffect(() => {
    const fetchEntries = async () => {
      if (userId) {
        // Make sure the user is logged in
        const fetchedEntries = await fetchJournalEntries(userId)
        setEntries(fetchedEntries)
      }
    }

    fetchEntries()
  }, [userId])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid)
      } else {
        setUserId(null)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  // Define the tutorial steps
  const tutorialSteps = [
    {
      target: '#title',
      content: 'Enter the title of your journal entry in this input field.'
    },
    {
      target: '.mood-selectors',
      content: 'Select your mood by clicking on one of the mood images.'
    },
    {
      target: '#thoughts',
      content: 'Write your thoughts in this textarea field.'
    },
    {
      target: '#feelings',
      content: 'Write your feelings in this textarea field.'
    },
    {
      target: '.submit-button',
      content: 'Click this button to create your journal entry.'
    },
    {
      target: '.prev-entries',
      content: 'You can view your previous journal entries in this section.'
    }
  ]

  const handleJoyrideCallback = data => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideRun(false);
      localStorage.setItem('tutorialSeen', 'true');
    }
  };
  

  useEffect(() => {
    if (userId) {
      const tutorialSeen = localStorage.getItem('tutorialSeen');
      if (!tutorialSeen) {
        setJoyrideRun(true);
      }
    }
  }, [userId]);
  

  return (
    <>
      <div className='specific-contact-page'>
        <Header />
        <Joyride
          steps={tutorialSteps}
          run={joyrideRun}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              primaryColor: '#82c0ba',
              zIndex: 10000
            }
          }}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                options: {
                  padding: 8,
                  boundary: 'clippingParents',
                  altBoundary: true,
                  fallbackPlacements: ['top', 'bottom', 'right', 'left'],
                  allowedAutoPlacements: ['top', 'bottom', 'right', 'left'],
                  mainAxis: true,
                  altAxis: true,
                  checkVariation: true,
                  detectOverflow: detectOverflow
                }
              }
            ]
          }}
        />
        <h1>Create a Journal Entry</h1>
        <div className='specific-contact-container'>
          <div className='journal-entry'>
            <label htmlFor='title'>Title</label>

            {/* Title input field */}
            <input
              type='text'
              id='title'
              name='title'
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder='Enter the title...'
            />
            {errors.title && <p className='error-message'>{errors.title}</p>}

            <label>Mood</label>
            <div className='mood-selectors'>
              {moods.map(m => (
                <img
                  key={m.value}
                  src={m.img}
                  alt={m.name}
                  onClick={() => setMood(m.value)}
                  className={mood === m.value ? 'selected' : ''}
                />
              ))}
            </div>
            <label htmlFor='thoughts'>Thoughts</label>

            {/* Thoughts textarea field */}
            <textarea
              id='thoughts'
              name='thoughts'
              value={thoughts}
              onChange={e => setThoughts(e.target.value)}
              placeholder='Write your thoughts...'
            />
            {errors.thoughts && (
              <p className='error-message'>{errors.thoughts}</p>
            )}

            <label htmlFor='feelings'>Feelings</label>

            {/* Feelings textarea field */}
            <textarea
              id='feelings'
              name='feelings'
              value={feelings}
              onChange={e => setFeelings(e.target.value)}
              placeholder='Write your feelings...'
            />
            {errors.feelings && (
              <p className='error-message'>{errors.feelings}</p>
            )}
          </div>
          <button className='submit-button' onClick={handleSubmit}>
            Create Entry
          </button>
        </div>
        <h2 class='prev-entries'>Previous Entries</h2>
        <div className='specific-contact-container'>
          <div className='journal-entries'>
            {entries.map(entry => (
              <JournalEntry
                key={entry.id}
                entry={entry}
                onDelete={handleDelete}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default JournalMoodTracker
