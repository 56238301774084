import React, { useState, useEffect } from "react";
import Header from './components/Header';
import Hero from './components/Hero';
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";
import "./styles/App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CallToAction from "./components/CallToAction";
import Profile from "./components/Profile";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import LoggedInContext from "./LoggedInContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import News from "./components/News";
import Blog from "./components/Blog";
import { Navigate } from 'react-router-dom';
import articles from "./articles.json";
import Contact from "./components/Contact";
import GetHelpNow from "./components/GetHelpNow"; // Add this import
import Dashboard from "./components/Dashboard";
import GoalsManagement from "./components/GoalsManagment";
import Journal from "./components/JournalMoodTracker";
import Chatbot from "./components/RestrictedPage";
import Resources from "./components/Resources";
import FAQs from "./components/FAQs";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LandingPage = ({ setLoggedIn }) => {
  return (
    <div>
      <Header setLoggedIn={setLoggedIn} />
      <Hero />
      <MainContent />
      <CallToAction />
      <FAQs/>
      <Footer />
    </div>
  );
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoggedIn(user !== null);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <LoggedInContext.Provider value={{ loggedIn, setLoggedIn }}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage setLoggedIn={setLoggedIn} />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:index" element={<Blog articles={articles} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gethelpnow" element={<GetHelpNow />} />
          <Route path="/resources" element={<Resources />} />


          <Route path="/dashboard" element={loggedIn ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/goalsmanagment" element={loggedIn ? <GoalsManagement /> : <Navigate to="/" />} />
          <Route path="/journal" element={loggedIn ? <Journal /> : <Navigate to="/" />} />
          <Route path="/chatbot" element={loggedIn ? <Chatbot user={user} /> : <Navigate to="/" />} />
          <Route path="/profile" element={loggedIn ? <Profile user={user} /> : <Navigate to="/" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </LoggedInContext.Provider>
  );
}

export default App;
