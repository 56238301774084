import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./../styles/GetHelpNow.css";

const GetHelpNow = () => {
    return (
        <>
            <div className="get-help-now-page">
                <Header />
                <h1>Get Help Now</h1>
                <div className="get-help-now-container">
                    <p>
                        If you're in a crisis or any other person may be in danger - don't
                        use this site. Use these resources for immediate help.
                    </p>

                    <div className="resources">
                        <h2>United States</h2>
                        <ul>
                            <li>Emergency: 911</li>
                            <li>Suicide &amp; Crisis Lifeline: Call or text 988</li>
                            <li>National Domestic Violence Hotline: 1-800-799-7233</li>
                            <li>Crisis Text Line: Text "DESERVE" TO 741-741</li>
                            <li>Lifeline Crisis Chat (Online live messaging): <a href="https://988lifeline.org/chat" target="_blank" rel="noopener noreferrer">https://988lifeline.org/chat</a></li>
                            <li>Self-Harm Hotline: 1-800-DONT CUT (1-800-366-8288)</li>
                            <li>Essential local and community services: 211, <a href="https://www.211.org/" target="_blank" rel="noopener noreferrer">https://www.211.org/</a></li>
                            <li>Planned Parenthood Hotline: 1-800-230-PLAN (7526)</li>
                            <li>American Association of Poison Control Centers: 1-800-222-1222</li>
                            <li>National Council on Alcoholism &amp; Drug Dependency Hope Line: 1-800-622-2255</li>
                            <li>National Crisis Line - Anorexia and Bulimia: 1-800-233-4357</li>
                            <li>LGBT Hotline: 1-888-843-4564</li>
                            <li>TREVOR Crisis Hotline: 1-866-488-7386</li>
                            <li>AIDS Crisis Line: 1-800-221-7044</li>
                            <li>Veterans Crisis Line: <a href="https://www.veteranscrisisline.net" target="_blank" rel="noopener noreferrer">https://www.veteranscrisisline.net</a></li>
                            <li>TransLifeline: <a href="https://www.translifeline.org" target="_blank" rel="noopener noreferrer">https://www.translifeline.org</a> - 877-565-8860</li>
                        </ul>

                        <h2>UK &amp; Republic of Ireland</h2>
                        <ul>
                            <li>Emergency: 112 or 999</li>
                            <li>Non-emergency: 111, Option 2</li>
                            <li>24/7 Helpline: 116 123 (UK and ROI)</li>
                            <li>Shout: Text "DESERVE" TO 85258</li>
                            <li>Samaritans.org: <a href="https://www.samaritans.org/how-we-can-help-you/contact-us" target="_blank" rel="noopener noreferrer">https://www.samaritans.org/how-we-can-help-you/contact-us</a></li>
                            <li>YourLifeCounts.org: <a href="https://yourlifecounts.org/find-help/" target="_blank" rel="noopener noreferrer">https://yourlifecounts.org/find-help/</a></li>
                        </ul>

                        <h2>Argentina</h2>
                        <ul>
                            <li>Emergency: 911</li>
                            <li>Recuerde siempre que si usted está en una situación de emergencia debe comunicarse con los teléfonos: *107 (SAME-Sistema de Atención Medica de Emergencia), *911 (Emergencia policial), para atención telefónica inmediata. Si desea orientación telefónica a familiares y amigos, déjenos su mensaje y teléfono. Nos comunicaremos con usted.</li>
                            <li>Argentina Suicide Hotline: +5402234930430</li>
                        </ul>

                        <h2>Spain</h2>
                        <ul>
                            <li>Emergency: 112</li>
                            <li>Teléfono De La Esperanza - 717-003-717  - <a href="http://telefonodelaesperanza.org/llamanos" target="_blank" rel="noopener noreferrer">http://telefonodelaesperanza.org/llamanos</a></li>
                        </ul>

                        <h2>Australia</h2>
                        <ul>
                            <li>Emergency: 000</li>
                            <li>Lifeline.org: <a href="https://www.lifeline.org.au/Get-Help/Online-Services/crisis-chat" target="_blank" rel="noopener noreferrer">https://www.lifeline.org.au/Get-Help/Online-Services/crisis-chat</a></li>
                            <li>LifeLine Australia: 13 11 14</li>
                            <li>YourLifeCounts.org: <a href="https://yourlifecounts.org/find-help/" target="_blank" rel="noopener noreferrer">https://yourlifecounts.org/find-help/</a></li>
                            <li>Beyond Blue <a href="https://www.beyondblue.org.au/get-support/get-immediate-support" target="_blank" rel="noopener noreferrer">https://www.beyondblue.org.au/get-support/get-immediate-support</a></li>
                        </ul>

                        <h2>China</h2>
                        <ul>
                            <li>Emergency 110</li>
                            <li>Beijing Suicide Research and Prevention Center <a href="http://www.crisis.org.cn/" target="_blank" rel="noopener noreferrer">http://www.crisis.org.cn/</a> 800-810-1117 (landline) or 010-8295-1332 (mobile and VoIP callers)</li>
                            <li>Shanghai Mental Health Center <a href="http://www.smhc.org.cn/" target="_blank" rel="noopener noreferrer">http://www.smhc.org.cn/</a></li>
                            <li>Lifeline Shanghai <a href="https://www.lifeline-shanghai.com/" target="_blank" rel="noopener noreferrer">https://www.lifeline-shanghai.com/</a></li>
                        </ul>

                        <h2>Canada</h2>
                        <ul>
                            <li>Emergency: 911</li>
                            <li>Crisis Text Line (Powered by Kids Help Phone): Text "DESERVE" TO 686868</li>
                            <li>YourLifeCounts.org: <a href="https://yourlifecounts.org/find-help/" target="_blank" rel="noopener noreferrer">https://yourlifecounts.org/find-help/</a></li>
                            <li>Crisis Services Canada: <a href="http://www.crisisservicescanada.ca/en/" target="_blank" rel="noopener noreferrer">http://www.crisisservicescanada.ca/en/</a></li>
                            <li>Canadian Association for Suicide Prevention: <a href="https://suicideprevention.ca/need-help/" target="_blank" rel="noopener noreferrer">https://suicideprevention.ca/need-help/</a></li>
                        </ul>

                        <h2>South Africa</h2>
                        <ul>
                            <li>Emergency: 10 111 for police or 10 177 for an ambulance</li>
                            <li>24hr Helpline: 0800 12 13 14 or SMS 31393 (and we will call you back)</li>
                            <li>Depression and Anxiety Helpline: 0800 70 80 90</li>
                            <li>YourLifeCounts.org: <a href="https://yourlifecounts.org/find-help/" target="_blank" rel="noopener noreferrer">https://yourlifecounts.org/find-help/</a></li>
                        </ul>

                        <h2>New Zealand</h2>
                        <ul>
                            <li>Emergency: 111</li>
                            <li>Lifeline 24/7 Helpline: 0800 543 354</li>
                            <li>Suicide Crisis Helpline: 0508 828 865 (0508 TAUTOKO)</li>
                            <li>YourLifeCounts.org: <a href="https://yourlifecounts.org/find-help/" target="_blank" rel="noopener noreferrer">https://yourlifecounts.org/find-help/</a></li>
                        </ul>

                        <h2>India</h2>
                        <ul>
                            <li>Emergency: 112</li>
                            <li>Sneha India (http://www.snehaindia.org) is available 24/7 on the phone by calling 91 44 24640050</li>
                        </ul>

                        <h2>Germany</h2>
                        <ul>
                            <li>Emergency: 112</li>
                            <li>Hotline: 800 111 0111</li>
                            <li>Hotline: 0800 111 0222</li>
                            <li>YourLifeCounts.org: <a href="https://yourlifecounts.org/find-help/" target="_blank" rel="noopener noreferrer">https://yourlifecounts.org/find-help/</a></li>
                        </ul>

                        <h2>Finland</h2>
                        <ul>
                            <li>Emergency: 112</li>
                            <li>Crisis Line: 010 195 202</li>
                        </ul>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default GetHelpNow;
