// textUtils.js

export const splitIntoChunks = (text, maxLength = 280) => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [text]
    const chunks = []
    let currentChunk = ''
  
    for (const sentence of sentences) {
      if ((currentChunk + sentence).length <= maxLength) {
        currentChunk += (currentChunk ? ' ' : '') + sentence.trim()
      } else {
        chunks.push(currentChunk)
        currentChunk = sentence.trim()
      }
    }
  
    if (currentChunk) {
      chunks.push(currentChunk)
    }
  
    return chunks
  };
  
  export const splitIntoParagraphs = text => {
    return text.trim().split(/\n+/)
  };
  
  export const processChatGPTOutput = async output => {
    const paragraphs = splitIntoParagraphs(output)
    const readableOutput = []
  
    for (const paragraph of paragraphs) {
      const chunks = splitIntoChunks(paragraph)
      readableOutput.push(...chunks)
    }
  
    return readableOutput
  };
  
  // conversation anaylze 
  export const analyzeConversationsAndRecommendResources = (conversations, allResources) => {
    const mentalHealthKeywords = ['anxiety', 'trauma', 'depression', 'stress'];
  
    const keywordCount = {};
  
    conversations.forEach((conversation) => {
      conversation.messages.forEach((message) => {
        if (message.sender === 'user') {
          mentalHealthKeywords.forEach((keyword) => {
            if (message.text.toLowerCase().includes(keyword)) {
              keywordCount[keyword] = (keywordCount[keyword] || 0) + 1;
            }
          });
        }
      });
    });
  
    // Sort keywords by count and get the most frequent one
    const sortedKeywords = Object.keys(keywordCount).sort((a, b) => keywordCount[b] - keywordCount[a]);
    const mostFrequentKeyword = sortedKeywords[0];
  
    if (mostFrequentKeyword) {
      // Recommend resources based on the most frequent keyword
      const recommendedResources = allResources.filter((resource) =>
        resource.title && resource.title.toLowerCase().includes(mostFrequentKeyword) // Add a check for resource.title
      );
      return recommendedResources;
    } else {
      return [];
    }
  };
  
  