import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./../styles/FAQs.css";  // Import the CSS for this component

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <span>{question}</span>
        <span>{isOpen ? <FaAngleUp color="#171331" /> : <FaAngleDown color="#171331" />}</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const FAQs = () => {
    const faqData = [
      {
        question: "What is Aidacura?",
        answer: "Aidacura is a revolutionary mental health tool box, designed to make therapy more accessible and affordable. It is a comprehensive mental wellness application that features a chatbot, mood tracking, goal setting, journaling, meditation, and community support."
      },
      {
        question: "How does the Aidacura chatbot work?",
        answer: "The Aidacura chatbot, an essential part of our mental health tools, is powered by ChatGPT's API. It utilizes natural language processing and machine learning to understand and respond to user inputs, providing personalized mental health advice, coping strategies, and resources based on the user's emotions, needs, and goals."
      },
      {
        question: "Can the Aidacura chatbot replace therapy with a licensed professional?",
        answer: "While Aidacura chatbot provides mental health advice and coping strategies, it does not replace therapy with a licensed professional. The chatbot is a part of our mental health tool kit designed to provide immediate assistance and refer users to licensed therapists and self-help resources if necessary."
      },
      {
        question: "Is Aidacura available worldwide?",
        answer: "Aidacura, is designed to be accessible to anyone with an internet connection, breaking down geographical barriers to mental health support. However, please note that specific features, such as referrals to local therapists, may be region-specific."
      },
      {
        question: "What features does Aidacura offer?",
        answer: "Aidacura offers a variety of mental health tools designed to support mental health, including mood tracking, goal setting, journaling, meditation, and community support. These features aim to empower users to take charge of their mental well-being."
      },
      {
        question: "How does Aidacura ensure privacy and confidentiality?",
        answer: "Aidacura prioritizes user privacy and confidentiality within our mental health tool kit. All interactions with the chatbot and within the platform are private and secure. For more detailed information, please refer to our Privacy Policy."
      },
      {
        question: "What if I need immediate crisis support?",
        answer: "Aidacura is not equipped to handle immediate crises. If you are in crisis, we strongly recommend reaching out to a trusted individual, a mental health professional, or a local crisis hotline."
      },
      // Add more FAQs here
    ];
    
  return (
    <div className="faqs">
      <h2>FAQs</h2>
      <div className="faq-container">
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
