import React, { useEffect } from 'react';
import aidacuraLogo from './../assets/aidacura-svg.svg';
import userLogo from './../assets/user.png';

const MessageList = ({ messages, isTyping, loadingIcon, chatContainerRef }) => {
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, chatContainerRef]);

  return (
    <div className='custom-message-list' ref={chatContainerRef}>
      {messages &&
        messages.map((message, index) => (
          <div key={index} className={`custom-message ${message.sender}`}>
            <div className={`message-logo-container ${message.sender === 'user' ? 'user' : ''}`}>
              <img
                src={message.sender === 'user' ? userLogo : aidacuraLogo}
                alt=''
                className='message-logo'
              />
            </div>
            <div className='custom-message-text'>{message.message}</div>
          </div>
        ))}
      {isTyping && (
        <div className='typing-indicator'>
          Aidacura is typing...
          <img src={loadingIcon} alt='Loading' className='loading-icon' />
        </div>
      )}
    </div>
  );
};

export default MessageList;
