import React from "react";

const ConversationsList = ({ conversations, onConversationClick, onDeleteConversation }) => {
  const sortedConversations = [...conversations].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="conversations-list">
      {sortedConversations.map((conversation) => (
        <div
          key={conversation.id}
          className={`conversation-item${conversation.active ? " active" : ""}`}
          onClick={() => onConversationClick(conversation.id)}
        >
          <div className="conversation-date">
            {new Date(conversation.date).toLocaleDateString()}
          </div>
          <button
            className="delete-conversation-button"
            onClick={(e) => {
              e.stopPropagation();
              onDeleteConversation(conversation.id);
            }}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
  
};

export default ConversationsList;
