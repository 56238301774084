import React from 'react';
import { Link } from 'react-router-dom';
import './../styles/ChatbotPreview.css';

const ChatbotPreview = () => {
  return (
    <Link to="/chatbot" className="chatbot-preview">
      <div className="chatbot-preview-container">
        <div className="custom-message-list">
          <div className="custom-message Aidacura">
            <div className="message-logo-container">
              <img src="/static/media/aidacura-svg.c9db77209af205ad52fe4ecd4eae18aa.svg" alt="" className="message-logo"/>
            </div>
            <div className="custom-message-text-dash">Hi there! I'm Aidacura, your mental health partner. Let's work together to achieve your goals and improve your wellbeing.
              How can I support you?
            </div>
          </div>
          <div className="custom-message Aidacura">
            <div className="message-logo-container">
              <img src="/static/media/aidacura-svg.c9db77209af205ad52fe4ecd4eae18aa.svg" alt="" className="message-logo"/>
            </div>
            <div className="custom-message-text-dash">Click here to start a new conversation with me.</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ChatbotPreview;