import React, { useState, useEffect, useCallback, useRef } from 'react'
import './../styles/CustomChat.css'
import loadingIcon from './../assets/loading.gif'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from './../config/firebaseConfig'
import { auth } from './../config/firebaseConfig'
import { v4 as uuidv4 } from 'uuid'
import MessageList from './MessageList'
import ConversationList from './ConversationList'
import InputForm from './InputForm'
import { processMessageToChatGPT } from './../utils/gptUtils'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import StatusIndicator from './StatusIndicator'
import { useSwipeable } from 'react-swipeable'
import Joyride, { STATUS } from 'react-joyride'
import { detectOverflow } from '@popperjs/core'

const API_KEY = process.env.REACT_APP_API_KEY

const Chatbot = () => {
  // State management
  const [messages, setMessages] = useState([
    {
      message: `Hi there! I'm Aidacura, your mental health partner. Let's work together to achieve your goals and improve your wellbeing. How can I support you?`,
      sender: 'Aidacura'
    },
    {
      message: `Did you know, if you are on mobile you can swipe right to show your past conversations.`,
      sender: 'Aidacura'
    }
  ])

  const [inputValue, setInputValue] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [lastInput, setLastInput] = useState('')
  const [conversations, setConversations] = useState([])
  const [userId, setUserId] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [inputError, setInputError] = useState('')
  const [isApiOnline, setIsApiOnline] = useState(true)
  const [isConversationsListVisible, setIsConversationsListVisible] =
    useState(false)
  const [joyrideRun, setJoyrideRun] = useState(false)

  // Ref for the chat container
  const chatContainerRef = useRef(null)

  // Auto-scroll to bottom function
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  // Auto-scroll to bottom when a new message is added
  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (window.innerWidth <= 768) {
        setIsConversationsListVisible(false)
      }
    },
    onSwipedRight: () => {
      if (window.innerWidth <= 768) {
        setIsConversationsListVisible(true)
      }
    }
  })

  // local storage related functions
  const saveConversationsToLocalStorage = useCallback(updatedConversations => {
    localStorage.setItem('conversations', JSON.stringify(updatedConversations))
  }, [])

  // Firebase related functions
  const saveConversationsToDatabase = useCallback(
    async updatedConversations => {
      if (userId) {
        const conversationsRef = doc(db, `conversations/${userId}`)
        await setDoc(conversationsRef, { conversations: updatedConversations })
      }
      saveConversationsToLocalStorage(updatedConversations)
    },
    [userId, saveConversationsToLocalStorage]
  )

  // Conversation management functions
  const createNewConversation = useCallback(() => {
    const welcomeMessage1 = {
      message: `Hi there! I'm Aidacura, your mental health partner. Let's work together to achieve your goals and improve your wellbeing. How can I support you?`,
      sender: 'Aidacura'
    }
    const welcomeMessage2 = {
      message: `Did you know, if you are on mobile you can swipe right to show your past conversations.`,
      sender: 'Aidacura'
    }

    const newConversation = {
      id: Date.now(),
      title: `Conversation ${new Date().toLocaleString()}`,
      messages: [welcomeMessage1, welcomeMessage2],
      active: true,
      date: new Date().toISOString()
    }

    const updatedConversations = [
      ...conversations.map(conversation => ({
        ...conversation,
        active: false
      })),
      newConversation
    ]
    setConversations(updatedConversations)
    saveConversationsToDatabase(updatedConversations)
    saveConversationsToLocalStorage(updatedConversations)
  }, [
    conversations,
    saveConversationsToDatabase,
    saveConversationsToLocalStorage
  ])

  // end of Conversation management functions

  //New Chat function
  const handleNewChatClick = async () => {
    const welcomeMessage1 = {
      message: `Hi there! I'm Aidacura, your mental health partner. Let's work together to achieve your goals and improve your wellbeing.\nHow can I support you?`,
      sender: 'Aidacura'
    }
    const welcomeMessage2 = {
      message: `Did you know, if you are on mobile you can swipe right to show your past conversations.`,
      sender: 'Aidacura'
    }

    const newConversation = {
      id: uuidv4(),
      date: new Date().toISOString(),
      active: true,
      messages: [welcomeMessage1, welcomeMessage2]
    }

    const updatedConversations = [...conversations, newConversation].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )

    setConversations(updatedConversations)
    await saveConversationsToDatabase(updatedConversations)
    setMessages(newConversation.messages)
  }
  // end of new chat function

  // Conversations Database Fetch
  const fetchConversationsFromDatabase = useCallback(async () => {
    const conversationsRef = doc(db, `conversations/${userId}`)

    const docSnapshot = await getDoc(conversationsRef)
    if (docSnapshot.exists()) {
      const data = docSnapshot.data()

      if (data.conversations) {
        setConversations([...data.conversations])
        const activeConversation = data.conversations.find(
          conversation => conversation.active
        )
        if (activeConversation) {
          setMessages(activeConversation.messages)
        } else {
          createNewConversation()
        }
      }
    } else {
      createNewConversation()
    }

    return docSnapshot.exists() ? docSnapshot.data() : null
  }, [userId, createNewConversation])

  useEffect(() => {
    if (userId) {
      fetchConversationsFromDatabase()
    }
  }, [userId])

  useEffect(() => {
    if (userId && isAuthenticated) {
      const loadConversations = async () => {
        const savedConversations = localStorage.getItem('conversations')
        if (savedConversations) {
          setConversations(JSON.parse(savedConversations))
        } else {
          const data = await fetchConversationsFromDatabase()
          if (!data) {
            createNewConversation()
          }
        }
      }
      loadConversations()
    }
  }, [userId, isAuthenticated])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsAuthenticated(true)
        setUserId(user.uid)
      } else {
        setIsAuthenticated(false)
        setUserId(null)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  // Functions related to sending and receiving messages
  const handleSend = async (e, regenerate = false) => {
    e.preventDefault()

    if (inputValue.trim() === '' && !regenerate) {
      setInputError('Input cannot be empty')
      return
    }

    setInputError('')
    setMessages([
      ...messages,
      {
        message: inputValue,
        sender: 'user',
        timestamp: new Date().toISOString()
      }
    ])
    setLastInput(inputValue)
    setInputValue('')
    setIsTyping(true)

    try {
      await processMessageToChatGPT(
        [
          ...messages,
          {
            message: regenerate ? lastInput : inputValue,
            sender: 'user',
            timestamp: new Date().toISOString()
          }
        ],
        setMessages,
        API_KEY,
        setIsApiOnline
      )
      setIsTyping(false)
    } catch (error) {
      toast.error(
        'An error occurred while sending your message. Please try again.'
      )
      setIsTyping(false)
    }
  }

  const updateActiveConversation = (conversations, messages) => {
    const activeConversationIndex = conversations.findIndex(
      conversation => conversation.active
    )

    if (activeConversationIndex !== -1) {
      conversations[activeConversationIndex].messages = [...messages]
    } else {
      conversations.push({
        id: Date.now(),
        title: `Conversation ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
        messages: [...messages],
        active: true,
        date: new Date().toISOString()
      })
    }

    return conversations
  }

  useEffect(() => {
    if (messages.length > 0) {
      const updatedConversations = updateActiveConversation(
        conversations,
        messages
      )
      setConversations(updatedConversations)
      saveConversationsToDatabase(updatedConversations)
    }
  }, [messages])

  const handleConversationClick = async conversationId => {
    // Save the current chat's messages before navigating to another chat

    const updatedConversations = setActiveConversation(
      conversations,
      conversationId
    )
    setConversations(updatedConversations)

    const selectedConversation = updatedConversations.find(
      conversation => conversation.id === conversationId
    )
    setMessages(selectedConversation.messages)
  }

  const setActiveConversation = (conversations, conversationId) => {
    return conversations
      .map(conversation => {
        if (conversation.id === conversationId) {
          return { ...conversation, active: true }
        } else {
          return { ...conversation, active: false }
        }
      })
      .sort((a, b) => new Date(b.date) - new Date(a.date))
  }

  const handleDeleteConversation = async conversationId => {
    const updatedConversations = conversations.filter(
      conversation => conversation.id !== conversationId
    )

    setConversations(updatedConversations)
    await saveConversationsToDatabase(updatedConversations)
  }

  // Define the tutorial steps
  const tutorialSteps = [
    {
      target: '.chat-section',
      content:
        'This is the chat section, where you can view the current conversation.'
    },
    {
      target: '.custom-message-input-container',
      content: 'Type your message to the chatbot in this input field.'
    },
    {
      target: '.saved-conversations',
      content:
        'This section displays your saved conversations. Click on a conversation to view the messages.'
    },
    {
      target: 'button[type="submit"]',
      content: 'Click this button to send your message.'
    }
  ]
  
  const handleJoyrideCallback = data => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideRun(false);
      localStorage.setItem('tutorialSeen', 'true');
    }
  };
  

  useEffect(() => {
    if (userId) {
      const tutorialSeen = localStorage.getItem('tutorialSeen');
      if (!tutorialSeen) {
        setJoyrideRun(true);
      }
    }
  }, [userId]);

  return (
    <div className='custom-chat-container'>
      <ToastContainer />
      <Joyride
        steps={tutorialSteps}
        run={joyrideRun}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#82c0ba',
            zIndex: 10000
          }
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                padding: 8,
                boundary: 'clippingParents',
                altBoundary: true,
                fallbackPlacements: ['top', 'bottom', 'right', 'left'],
                allowedAutoPlacements: ['top', 'bottom', 'right', 'left'],
                mainAxis: true,
                altAxis: true,
                checkVariation: true,
                detectOverflow: detectOverflow
              }
            }
          ]
        }}
      />
      <div
        className='saved-conversations'
        style={{
          display:
            isConversationsListVisible || window.innerWidth > 768
              ? 'block'
              : 'none'
        }}
      >
        <ConversationList
          conversations={conversations}
          onConversationClick={handleConversationClick}
          onDeleteConversation={handleDeleteConversation}
          handleNewChatClick={handleNewChatClick}
        />
      </div>
      <div className='chat-section' {...swipeHandlers}>
        <MessageList
          messages={messages}
          isTyping={isTyping}
          loadingIcon={loadingIcon}
          chatContainerRef={chatContainerRef}
        />
        <InputForm
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSend={handleSend}
          inputError={inputError}
          setInputError={setInputError}
          isApiOnline={isApiOnline}
        />
      </div>
      <div className='status-indicator-container'>
        <StatusIndicator isApiOnline={isApiOnline} />
      </div>
    </div>
  )
}

export default Chatbot
