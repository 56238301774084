import React from "react";
import Footer from "./Footer";
import Header from './Header';
import "./../styles/Terms.css";

const Terms = () => {
    return (
        <div className="terms-container">
            <Header />
            <h1>Terms of Service</h1>
            <p>Welcome to our mental health platform! These terms of service (&ldquo;Terms&rdquo;) govern your use of our website and mobile application (collectively, the &ldquo;Platform&rdquo;) which aim to make therapy more accessible and affordable for people who struggle with mental health issues. Please read these Terms carefully before using the Platform.</p>

            <p>By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Platform.</p>

            <p>Your use of the Platform a. Eligibility: You must be at least 18 years old to use the Platform. If you are under 18 years old, you must have the permission of your parent or legal guardian to use the Platform.</p>

            <p>b. Registration: To access certain features of the Platform, you may need to register for an account. When you register, you must provide accurate and complete information. You are solely responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

            <p>c. Use of the Platform: You may use the Platform only for lawful purposes and in accordance with these Terms. You agree not to use the Platform:</p>

            <p>i. In any way that violates any applicable federal, state, local, or international law or regulation; ii. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise; iii. To transmit, or procure the sending of, any advertising or promotional material, including any &ldquo;junk mail,&rdquo; &ldquo;chain letter,&rdquo; &ldquo;spam,&rdquo; or any other similar solicitation; iv. To impersonate or attempt to impersonate us, a company employee, another user, or any other person or entity; v. To engage in any other conduct that restricts or inhibits anyone&rsquo;s use or enjoyment of the Platform, or which, as determined by us, may harm us or users of the Platform or expose them to liability.</p>

            <p>d. Our Content: The Platform and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by us, our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

            <p>e. User Content: You are solely responsible for any content, including but not limited to text, photographs, and videos, that you submit, post, or display on or through the Platform (&ldquo;User Content&rdquo;). You represent and warrant that you have all rights necessary to submit, post, or display such User Content, and that such User Content, and your use of the Platform, do not violate these Terms, any applicable laws or regulations, or the rights of any third party.</p>

            <p>f. Monitoring and Enforcement: We have the right to:</p>

            <p>i. Remove or refuse to post any User Content for any reason in our sole discretion; ii. Take any action with respect to any User Content that we deem necessary or appropriate in our sole discretion, including if we believe that such User Content violates these Terms, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Platform or the public, or could create liability for us; iii. Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy; iv. Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Platform; or v. Terminate or suspend your access to all or part of the Platform for any or no reason</p>
            <Footer />
        </div>
        
    );
};

export default Terms;
