import React, { useState, useEffect } from "react";
import { fetchGoals } from "./../helpers/api";

const GoalsList = ({ userId }) => {
  const [goals, setGoals] = useState([]);

  const fetchGoalsData = async () => {
    if (userId) {
      const fetchedGoals = await fetchGoals(userId);
      if (Array.isArray(fetchedGoals)) {
        setGoals(fetchedGoals);
      } else {
        console.error("Error: fetchedGoals is not an array");
      }
    }
  };

  useEffect(() => {
    fetchGoalsData();
  }, [userId]);

  return (
    <ul className="goals-list">
      {goals
        .filter((goal) => !goal.completed)
        .slice(0, 7)
        .map((goal) => (
          <li key={goal.id} className="goal-item">
            <span
              className={`goal-term ${
                goal.term === "short" ? "short-term" : "long-term"
              }`}
            ></span>
            {goal.text}
          </li>
        ))}
    </ul>
  );
};

export default GoalsList;
