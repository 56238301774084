import React from 'react';
import testi1 from '../assets/testi1.svg';
import testi2 from '../assets/testi2.svg';
import testi3 from '../assets/testi3.svg';
import "./../styles/Testimonials.css";

// The Testimonials component
const Testimonials = () => {
  // An array containing the testimonial data
  const testimonialsData = [
      {
        name: 'Demi Barker',
        quote: 'This platform has helped me immensely in dealing with my anxiety.',
        image: testi1,
      },
      {
        name: 'Isabel Doherty',
        quote: 'Aidacura has provided me with the tools to improve my mental well-being.',
        image: testi2,
      },
      {
        name: 'Kian Jenkins',
        quote: 'This platform has helped me immensely in dealing with my anxiety.',
        image: testi3,
      },
  
  ];


  return (
    <section className="testimonials">
      <h2>What people are saying...</h2>
      <div className="testimonials-container">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div className="testimonial-image-container">
              <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            </div>
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-name">- {testimonial.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;