import React, { useState, useContext } from "react";
import LoggedInContext from "./../LoggedInContext";
import "./../styles/Header.css";
import logo from "./../assets/Aidacura.svg";
import svg from "./../assets/aidacura-svg.svg";
import AuthForm from "./auth/AuthForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebaseConfig";

const Header = () => {
  const { loggedIn, setLoggedIn } = useContext(LoggedInContext);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [authMode, setAuthMode] = useState("signIn");
  const navigate = useNavigate();
  const [mobileNavVisible, setMobileNavVisible] = useState(false);

  const openSignInForm = (e) => {
    e.preventDefault();
    setAuthMode("signIn");
    setShowAuthForm(true);
  };

  const openSignUpForm = (e) => {
    e.preventDefault();
    setAuthMode("signUp");
    setShowAuthForm(true);
  };

  const closeAuthForm = () => {
    setShowAuthForm(false);
  };

  const signOut = async (e) => {
    e.preventDefault();
    try {
      await auth.signOut();
      setLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const toggleMobileNav = () => {
    setMobileNavVisible(!mobileNavVisible);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={svg} alt="Aidacura Logo" style={{ width: "32px", marginRight: "10px" }} />
          <img src={logo} alt="Aidacura Logo" />
        </Link>
      </div>
      <button className="mobile-menu-button" onClick={toggleMobileNav}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <nav className={mobileNavVisible ? "mobile-nav active" : "mobile-nav"}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          <li>
            <a href="/resources">Resources</a>
          </li>
          {loggedIn && (
            <li>
              <Link to="/dashboard">Dashboard</Link>

            </li>
          )}
        </ul>
        <div className="cta">
          {loggedIn ? (
            <>
              <Link to="/profile" className="cta-link">
                <FontAwesomeIcon icon={faUser} />
              </Link>
              <a href="#signout" className="cta-button" onClick={signOut}>
                Sign Out
              </a>
            </>
          ) : (
            <>
              <a href="#signin" className="cta-link" onClick={openSignInForm}>
                Sign In
              </a>
              <a href="#signup" className="cta-button" onClick={openSignUpForm}>
                Try for Free
              </a>
            </>
          )}
        </div>
      </nav>

      {showAuthForm && (
        <div className="auth-form-container">
          <AuthForm mode={authMode} setLoggedIn={setLoggedIn} closeAuthForm={closeAuthForm} />
          <div className="auth-form-close" onClick={closeAuthForm}>
            ×
          </div>
        </div>
      )}

    </header>
  );
};

export default Header;
