import React from "react";
import Header from './Header';
import Footer from './Footer';
import "./../styles/Privacy.css";

const Privacy = () => {
    return (
        <div className="privacy-container">
            <Header />
            <h1>Privacy Policy</h1>
            <p>Thank you for using our mental health platform! We respect your privacy and are committed to protecting your personal information. This Privacy Policy (&ldquo;Policy&rdquo;) describes the types of information we collect from you, how we use that information, and how we protect your information.</p>

<p>Information We Collect a. Personal Information: When you register for an account, we may collect your name, email address, and other personal information.</p>

<p>b. Chatbot Interactions: When you use our chatbot, we may collect information about your mental health, emotions, needs, and goals to provide you with personalized suggestions and coping strategies.</p>

<p>c. Community Interactions: When you participate in our community features, such as forums or groups, we may collect information that you choose to share with us, such as your username or posts.</p>

<p>d. Device Information: We may collect information about the device you use to access the Platform, such as your IP address, browser type, and operating system.</p>

<p>How We Use Your Information a. To provide the Platform: We use your information to provide you with access to the Platform and its features, such as the chatbot and community features.</p>

<p>b. To improve the Platform: We use your information to improve the Platform and its features, such as by analyzing user behavior and preferences.</p>

<p>c. To communicate with you: We may use your information to communicate with you about the Platform, such as to notify you of changes to our Terms or to respond to your inquiries.</p>

<p>d. To personalize your experience: We use your information to personalize your experience on the Platform, such as by providing personalized suggestions and coping strategies through the chatbot.</p>

<p>e. To comply with legal obligations: We may use your information to comply with our legal obligations, such as to respond to subpoenas or other legal requests.</p>

<p>How We Protect Your Information a. Security Measures: We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>

<p>b. Third-Party Service Providers: We use third-party service providers, such as cloud hosting providers, to help us operate the Platform. We require these service providers to use reasonable security measures to protect your information.</p>

<p>c. Data Retention: We retain your information for as long as necessary to provide you with access to the Platform and to comply with our legal obligations.</p>

<p>Your Rights a. Access and Correction: You have the right to access and correct your personal information.</p>

<p>b. Deletion: You have the right to request the deletion of your personal information.</p>

<p>c. Opt-Out: You may opt-out of receiving marketing emails from us at any time.</p>

<p>Changes to This Policy We may update this Policy from time to time. If we make material changes to this Policy, we will notify you by email or by posting a notice on the Platform. Your continued use of the Platform after the effective date of any changes to this Policy constitutes your acceptance of such changes.</p>

<p>If you have any questions or concerns about this Policy, please contact us at <b>joel@aidacura.com</b></p>
<Footer />
        </div>
    );
};

export default Privacy;
