import React from "react";

import "./../styles/Hero.css";
import groupsvg from "./../assets/Group 13.svg"

const Hero = () => {

  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero-row">
          <div className="hero-text">
            <h1>Welcome to <span style={{ color: '#ffffff' }}>Aida</span><span style={{ color: '#A5F9E8' }}>cura</span></h1>
            <h2>Your new mental health partner</h2>
          </div>
          <img src={groupsvg} alt="Group" className="group-image" />
        </div>
        <div className="info-box">
          <p>Aidacura is your online mental health tool kit committed to offering comprehensive care through state-of-the-art AI-powered chatbots, tailored resources, mood-tracking and community support, all within a <span style={{ color: '#A5F9E8' }}>secure and confidential environment</span>. We provide essential mental health tools aiming to empower individuals in taking charge of their mental well-being and breaking down the barriers to seeking professional help.</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
