// Import required modules and components
import React, { useState } from "react";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";
import "./AuthForm.css";
import { useNavigate } from 'react-router-dom';
import { auth } from "../../config/firebaseConfig";
import Cookies from 'js-cookie';
import { setCookie } from '../../utils/cookies';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
    getDocs,
    query,
    collection,
    where,
    setDoc,
    doc,
    updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { getFunctions, httpsCallable } from "firebase/functions";


// AuthForm component, taking the 'mode' prop to determine if it's for registration or sign in
const AuthForm = ({ mode, setLoggedIn, closeAuthForm }) => {

    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            // The signed-in user info.
            const user = result.user;

            // Check if there's an existing account with the same email address
            const userSnapshot = await getDocs(query(collection(db, "users"), where("email", "==", user.email)));

            if (userSnapshot.empty) {
                // Create a new user in the database
                await setDoc(doc(collection(db, "users"), user.uid), {
                    email: user.email,
                    displayName: user.displayName,
                });
            } else {
                // The user has an existing account, so update the user's uid
                const existingUser = userSnapshot.docs[0];
                await updateDoc(doc(db, "users", existingUser.id), { uid: user.uid });
            }

            // Call updateLastActivity here
            updateLastActivity().catch((error) => {
                console.error("Error updating last activity: ", error);
            });

            // Get the Access Token using the getIdToken() method
            const token = await user.getIdToken();

            setLoggedIn(true);
            closeAuthForm();
            navigate('/dashboard');
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage(error.message);
        }
    };


    // Determine if the form should be in registration mode or sign in mode
    const isRegister = mode === "signUp";
    // State hooks for handling messages, loading status, reset email, and reset email status
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [resetEmailSent, setResetEmailSent] = useState(false);

    // Remeber Me
    const [rememberMe, setRememberMe] = useState(false);
    const [emailInput, setEmailInput] = useState(() => Cookies.get("rememberMe") || "");

    // Function to validate password according to specific criteria
    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        // Return true if all conditions are met, otherwise false
        if (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumber &&
            hasSpecialChar
        ) {
            return true;
        }
        return false;
    };

    // restricted page const
    const navigate = useNavigate();

    // Function to validate email format using a regex pattern
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Function to check if the password and confirmPassword values match
    const passwordsMatch = (password, confirmPassword) => {
        return password === confirmPassword;
    };

    // Function to handle sending password reset email
    const handlePasswordReset = async () => {
        // Check if the email is valid, if not set an error message
        if (!validateEmail(resetEmail)) {
            setErrorMessage("Invalid email format.");
            return;
        }

        // Send the reset email and handle any errors that may occur
        try {
            const authInstance = getAuth();
            await sendPasswordResetEmail(authInstance, resetEmail);
            setResetEmailSent(true);
            setErrorMessage("");
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage(error.message);
        }
    };

    // cloud functions
    const functions = getFunctions();
    const updateLastActivity = httpsCallable(functions, 'updateLastActivity');



    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        const rememberMe = e.target.rememberMe?.checked;

        if (isRegister) {
            const confirmPassword = e.target.confirmPassword.value;
            if (!passwordsMatch(password, confirmPassword)) {
                setErrorMessage("Passwords do not match.");
                return;
            }

            if (!validatePassword(password)) {
                setErrorMessage(
                    "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character."
                );
                return;
            }
        }

        if (!validateEmail(email)) {
            setErrorMessage("Invalid email format.");
            return;
        }

        setIsLoading(true);

        try {
            let userCredential;
            if (isRegister) {
                userCredential = await createUserWithEmailAndPassword(auth, email, password);
                setSuccessMessage("Registration successful. Please sign in.");
            } else {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
                setSuccessMessage("Signed in successfully.");
                setLoggedIn(true);
                closeAuthForm();

                // Call updateLastActivity here
                updateLastActivity().catch((error) => {
                    console.error("Error updating last activity: ", error);
                });

                if (rememberMe) {
                    const userId = userCredential.user.uid;
                    setCookie('userId', userId, 7); // Store the user ID in a cookie for 7 days
                }
            }
            console.log("User registered or logged in:", userCredential);
            setErrorMessage("");
            navigate('/dashboard');
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    // Render the AuthForm component
    return (
        <div className="auth-form">
            <h2>{isRegister ? "Register" : "Sign In"}</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            {isLoading && <p>Loading...</p>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={emailInput}
                    onChange={(e) => {
                        setResetEmail(e.target.value);
                        setEmailInput(e.target.value);
                    }}
                    autoComplete="off"
                />

                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="off"
                />

                {!isRegister && (
                    <>
                        <label htmlFor="rememberMe" className="remember-me-label">
                            <input
                                type="checkbox"
                                name="rememberMe"
                                id="rememberMe"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            Remember me
                        </label>
                    </>
                )}

                {isRegister && (
                    <>
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            autoComplete="off"
                        />
                    </>
                )}

                <button type="submit">{isRegister ? "Register" : "Sign In"}</button>

                <button
                    type="button"
                    onClick={handleGoogleSignIn}
                    className="google-signin-button"
                >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="Google Logo" />
                    Sign In with Google
                </button>



                <button
                    type="button"
                    onClick={handlePasswordReset}
                    className="forgot-password-button"
                >
                    Forgot Password
                </button>

                {resetEmailSent && (
                    <p className="success-message">Password reset email sent.</p>
                )}

                <p className="terms-notice">
                    By continuing, you agree to Aidacura's{" "}
                    <a href="/terms" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a href="/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </p>

            </form>

        </div>
    );
};

export default AuthForm;
