import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './../styles/CallToAction.css';
import AuthForm from "./auth/AuthForm";
import { auth } from './../config/firebaseConfig';
import { useNavigate } from "react-router-dom";

const CallToAction = () => {
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleJoinNowClick = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      setShowAuthForm(true);
    }
  };

  const closeAuthForm = () => {
    setShowAuthForm(false);
  };

  const buttonAnimation = useSpring({
    from: { transform: 'scale(1)' },
    to: async (next) => {
      while (true) {
        await next({ transform: 'scale(1.05)' });
        await next({ transform: 'scale(1)' });
      }
    },
    config: { tension: 200, friction: 10 },
  });

  const navigate = useNavigate();

  return (
    <div className="call-to-action">
      <h2 className="cta-title">
        Discover the Power of
        <span style={{ color: "#A5F9E8" }}> Aidacura </span>
        today
      </h2>

      <animated.button className="cta-button" onClick={handleJoinNowClick} style={buttonAnimation}>Join Now</animated.button>

      {showAuthForm && (
        <div className="auth-form-overlay">
          <AuthForm mode="signUp" closeAuthForm={closeAuthForm} />
        </div>
      )}
    </div>
  );
};

export default CallToAction;
