import React, { useState, useEffect } from 'react'
import {
  getAuth,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth'
import Header from './Header'
import Footer from './Footer';
import './../styles/Profile.css'

const Profile = ({ loggedIn, setLoggedIn }) => {
  const auth = getAuth()
  const currentUser = auth.currentUser
  const [email, setEmail] = useState(currentUser.email || '')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  useEffect(() => {
    document.title = 'Profile - Aidacura'
  }, [])

  const updateEmailInfo = async e => {
    e.preventDefault()
    try {
      const userCredential = await reauthenticateWithCredential(
        currentUser,
        EmailAuthProvider.credential(currentUser.email, password)
      )
      await updateEmail(userCredential.user, email)
      alert('Email updated successfully.')
    } catch (error) {
      alert('Error updating email: ' + error.message)
    }
  }

  const updatePasswordInfo = async e => {
    e.preventDefault()
    if (newPassword !== confirmNewPassword) {
      alert('New password and confirm password do not match.')
      return
    }
    try {
      const userCredential = await reauthenticateWithCredential(
        currentUser,
        EmailAuthProvider.credential(currentUser.email, password)
      )
      await userCredential.user.updatePassword(newPassword)
      alert('Password updated successfully.')
    } catch (error) {
      alert('Error updating password: ' + error.message)
    }
  }

  return (
    <>
      <Header loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      <div className="profile-page">
        <h1>Profile</h1>
        <div className="profile-container">
        <form onSubmit={updateEmailInfo} className="profile-form">
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="currentPassword">Current Password:</label>
            <input
              type="password"
              id="currentPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="submit-button">
            Update Email
          </button>
        </form>
        <form onSubmit={updatePasswordInfo} className="profile-form">
          <div>
            <label htmlFor="currentPassword2">Current Password:</label>
            <input
              type="password"
              id="currentPassword2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="confirmNewPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="submit-button">
            Update Password
          </button>
        </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Profile;
