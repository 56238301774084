import axios from "axios";
import { getDoc, doc } from "firebase/firestore";
import { db } from "./../config/firebaseConfig";
import { API_BASE_URL } from "./../config/config";

const instance = axios.create({
  baseURL: API_BASE_URL,
});

// goal CRUD operations
export const createGoal = async (goal) => {
  try {
    const response = await instance.post("/goals", goal);
    return response.data;
  } catch (error) {
    console.error("Error creating goal:", error);
    throw error;
  }
};

export const fetchGoals = async (userId) => {
  try {
    const response = await instance.get("/goals", {
      params: {
        userId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching goals:", error);
    throw error;
  }
};

export const updateGoal = async (userId, id, goal) => {
  try {
    const response = await instance.put(`/goals/${userId}/${id}`, goal);
    return response.data;
  } catch (error) {
    console.error("Error updating goal:", error);
    throw error;
  }
};

export const deleteGoal = async (userId, id) => {
  try {
    await instance.delete(`/goals/${userId}/${id}`);
  } catch (error) {
    console.error("Error deleting goal:", error);
    throw error;
  }
};

// Add the new functions for subtask CRUD operations below the existing functions

export const createSubTask = async (subtask) => {
  try {
    const response = await instance.post(`/goals/${subtask.userId}/${subtask.goalId}/subtasks`, subtask);
    return response.data;
  } catch (error) {
    console.error("Error creating subtask:", error);
    throw error;
  }
};

export const updateSubTask = async (userId, subtaskId, subtask) => {
  try {
    const response = await instance.put(`/goals/${userId}/${subtask.goalId}/subtasks/${subtaskId}`, subtask);
    return response.data;
  } catch (error) {
    console.error("Error updating subtask:", error);
    throw error;
  }
};

export const deleteSubTask = async (userId, goalId, subtaskId) => {
  try {
    await instance.delete(`/goals/${userId}/${goalId}/subtasks/${subtaskId}`);
  } catch (error) {
    console.error("Error deleting subtask:", error);
    throw error;
  }
};

export const toggleSubTaskCompletion = async (userId, goalId, subtaskId) => {
  try {
    const response = await instance.put(`/goals/${userId}/${goalId}/subtasks/${subtaskId}/toggle`);
    return response.data;
  } catch (error) {
    console.error("Error toggling subtask completion:", error);
    throw error;
  }
};

// Fetch subtasks for a specific goal
export const fetchSubTasks = async (userId, goalId) => {
  try {
    const response = await instance.get(`/goals/${userId}/${goalId}/subtasks`);
    return response.data;
  } catch (error) {
    console.error("Error fetching subtasks:", error);
    throw error;
  }
};



// journal crud operations

export const createJournalEntry = async (entry) => {
  try {
    const response = await instance.post("/journal-entries", entry);
    return response.data;
  } catch (error) {
    console.error("Error creating journal entry:", error);
    throw error;
  }
};

export const fetchJournalEntries = async (userId) => {
  try {
    const response = await instance.get("/journal-entries", {
      params: {
        userId,
      },
    });
    // Sort the fetched entries by id in descending order, if response.data is an array
    if (Array.isArray(response.data)) {
      return response.data.sort((a, b) => b.id - a.id);
    } else {
      // Handle the case where response.data is not an array
      console.error("Error: response.data is not an array");
      return [];
    }
  } catch (error) {
    console.error("Error fetching journal entries:", error);
    throw error;
  }
};


export const deleteJournalEntry = async (userId, id) => {
  try {
    await instance.delete(`/journal-entries/${userId}/${id}`);
  } catch (error) {
    console.error("Error deleting journal entry:", error);
    throw error;
  }
};

// fetch user message count
export const fetchUserMessagesCount = async (userId) => {
  if (!userId) return;

  const conversationsRef = doc(db, `conversations/${userId}`);
  const docSnapshot = await getDoc(conversationsRef);

  if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    const conversations = data.conversations || [];

    let userMessagesCount = {};

    conversations.forEach((conversation) => {
      conversation.messages.forEach((message) => {
        if (message.sender === 'user' && message.timestamp) {
          const dateKey = new Date(message.timestamp).toISOString().slice(0, 10);

          if (!userMessagesCount[dateKey]) {
            userMessagesCount[dateKey] = 0;
          }
          userMessagesCount[dateKey] += 1;
        }
      });
    });

    return userMessagesCount;
  }

  return {};
};

// fetch user conversations
export const fetchUserConversations = async (userId) => {
  if (!userId) return;

  const conversationsRef = doc(db, `conversations/${userId}`);
  const docSnapshot = await getDoc(conversationsRef);

  if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    const conversations = data.conversations || [];
    return conversations;
  }

  return [];
};


// Resource CRUD
export const fetchResources = async () => {
  try {
    const response = await instance.get('/resources');
    return response.data;
  } catch (error) {
    console.error("Error fetching resources:", error);
    throw error;
  }
};

export const fetchResourcesByCategory = async (category) => {
  try {
    const response = await instance.get(`/resources/category/${category}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching resources by category:", error);
    throw error;
  }
};


export const createResource = async (resource) => {
  try {
    const response = await instance.post("/resources", resource);
    return response.data;
  } catch (error) {
    console.error("Error creating resource:", error);
    throw error;
  }
};

export const updateResource = async (id, resource) => {
  try {
    const response = await instance.put(`/resources/${id}`, resource);
    return response.data;
  } catch (error) {
    console.error("Error updating resource:", error);
    throw error;
  }
};

export const deleteResource = async (id) => {
  try {
    await instance.delete(`/resources/${id}`);
  } catch (error) {
    console.error("Error deleting resource:", error);
    throw error;
  }
};


