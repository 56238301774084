// gptUtils.js
import { processChatGPTOutput } from './textUtils'
import { toast } from 'react-toastify'

let requestQueue = []
let isProcessingQueue = false

export async function processMessageToChatGPT(
  chatMessages,
  setMessages,
  API_KEY,
  setIsApiOnline
) {
  let apiMessages = chatMessages.map(messageObject => {
    let role = ''
    if (messageObject.sender === 'Aidacura') {
      role = 'assistant'
    } else {
      role = 'user'
    }
    return { role: role, content: messageObject.message }
  })

  const systemMessage = {
    role: 'system',
    content:
      `Your name is Aidacura. You act like a therapist with 25 years experience. You are happy to give advice and help users with mental health issues.` +
      ` You should not give definitive advice and try to keep the conversation flowing while giving advice here and there while trying to understand the users problems.` +
      ` Keep replies very short and inquisitive, remember this. As you are a therapist, your role is to help the user to explore their thoughts and emotions, identify patterns and behaviors that may be contributing to their challenges, and find ways to improve their mental health and wellbeing.` +
      ` You believe that everyone has the capacity for growth and change, and you're here to support them on their journey.` +
      ` Whether they're dealing with a specific issue or just looking to improve their overall mental health, you're committed to working with them to find the best approach for their needs.` +
      ` You will build a trusting and supportive relationship between the user and yourself, that helps them feel heard, understood, and empowered to make positive changes in their life.` +
      ` If you offer specific techniques, please explain them for the user.` +
      ` DO NOT OUTPUT LONG PASSAGES OF TEXT ` +
      ` Do not mention external therapists or support groups unless they speak about suicide or self harm. `
  }

  const apiRequestBody = {
    model: 'gpt-3.5-turbo',
    messages: [systemMessage, ...apiMessages]
  }

  requestQueue.push(apiRequestBody)

  if (!isProcessingQueue) {
    isProcessingQueue = true

    while (requestQueue.length > 0) {
      const currentRequest = requestQueue.shift()

      try {
        const response = await fetch(
          'https://api.openai.com/v1/chat/completions',
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + API_KEY,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(currentRequest)
          }
        )

        if (response.status === 429) {
          // Handle rate limit exceeded error
          setIsApiOnline(false)
          console.error('Rate limit exceeded')
          return
        } else {
          setIsApiOnline(true)
        }

        const data = await response.json()

        if (!data || !data.choices || !data.choices[0]) {
          // Handle invalid API response
          console.error('Invalid API response')
          toast.error(
            'Error processing the response from ChatGPT API. Please try again later.'
          )
          return
        }

        // Process the response
        const generatedMessage = data.choices[0].message.content

        // Call the processChatGPTOutput function to split the generated message
        const processedMessages = await processChatGPTOutput(generatedMessage)

        // Update the messages state with the processed messages
        setMessages(prevMessages => [
          ...prevMessages,
          ...processedMessages.map(message => ({
            message,
            sender: 'Aidacura'
          }))
        ])
      } catch (error) {
        console.error('Error while processing message:')
        // Handle network errors
        setIsApiOnline(false)
        toast.error('ChatGPT API is offline, please try again later.')
      }
      // Wait before processing the next request in the queue
      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    isProcessingQueue = false
  }
}
