import React, { useState, useEffect } from 'react';
import './../styles/Resources.css';
import { fetchResourcesByCategory } from './../helpers/api';
import Header from "./Header";
import Footer from "./Footer";
import Select from 'react-select';

const Resources = () => {
  const resourceCategories = [
    'Podcasts',
    'Videos',
    'Playlists',
    'Meditation and Mindfulness',
    'Articles and Blog Posts',
    'Self-help Books',
    'Online Communities',

  ];

  const [resources, setResources] = useState({});
  const [scrollingPaused, setScrollingPaused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategory, setFilteredCategory] = useState('');

  const categoryOptions = resourceCategories.map((category) => ({
    value: category,
    label: category,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#494763',
      borderColor: 'transparent',
      width: '200px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#82C0BA' : '#494763', // Change the highlight color here
      color: 'white',
      fontSize: '14px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      fontSize: '14px',
    }),
    input: (provided) => ({
      ...provided,
      color: '#ccc',
      fontSize: '14px',
    }),
  };


  useEffect(() => {
    const fetchData = async () => {
      const fetchedResources = {};

      for (const category of resourceCategories) {
        const data = await fetchResourcesByCategory(category);
        fetchedResources[category] = data;
      }

      setResources(fetchedResources);
    };

    fetchData();
  }, []);


  useEffect(() => {
    const scrollResources = () => {
      if (scrollingPaused) {
        return;
      }

      const resourceItems = document.querySelectorAll('.resource-items');

      resourceItems.forEach((item) => {
        item.scrollLeft += 1;
        if (item.scrollLeft + item.clientWidth >= item.scrollWidth) {
          item.scrollLeft = 0;
        }
      });
    };

    const scrollInterval = setInterval(scrollResources, 100);
    return () => clearInterval(scrollInterval);
  }, [scrollingPaused]);

  const pauseScrolling = () => {
    setScrollingPaused(true);
  };

  const resumeScrolling = () => {
    setScrollingPaused(false);
  };

  return (
    <>
      <Header />
      <h1 className="resources-header">Resources</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search resources..."
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Select
          styles={customStyles}
          options={categoryOptions}
          value={filteredCategory ? { value: filteredCategory, label: filteredCategory } : null}
          onChange={(selectedOption) => setFilteredCategory(selectedOption ? selectedOption.value : '')}
          placeholder="All Categories"
          isClearable
        />
      </div>

      <div className="resources">
        {resourceCategories.map((category) => {
          if (filteredCategory && filteredCategory !== category) {
            return null;
          }

          const filteredResources = resources[category]?.filter((resource) =>
            resource.title.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (!filteredResources || filteredResources.length === 0) {
            return null;
          }

          return (
            <div key={`resource-category-${category}`} className="resource-category">
              <h2 className="resource-category-title">{category}</h2>
              <div className="resource-items" onMouseOver={pauseScrolling} onMouseOut={resumeScrolling}>
                {filteredResources.map((resource, index) => (
                  <div key={resource.id} className="resource-item">
                    <h3>{resource.title}</h3>

                    {resource.type === 'spotify' && resource.link ? (
                      resource.link.includes('/show/') ? (
                        <iframe
                          title="Spotify Podcast"
                          src={`https://open.spotify.com/embed/show/${resource.link.split('/show/')[1].split('?si=')[0]}`}
                          width="100%"
                          height="232"
                          frameBorder="0"
                          allowtransparency="true"
                          allow="encrypted-media"
                        ></iframe>
                      ) : resource.link.includes('/playlist/') ? (
                        <iframe
                          title="Spotify Playlist"
                          src={`https://open.spotify.com/embed/playlist/${resource.link.split('/playlist/')[1].split('?si=')[0]}`}
                          width="100%"
                          height="232"
                          frameBorder="0"
                          allowTransparency="true"
                          allow="encrypted-media"
                        ></iframe>
                      ) : null
                    ) : resource.type === 'youtube' && resource.link ? (
                      <iframe
                        title="YouTube Video"
                        className="youtube-video"
                        src={`https://www.youtube.com/embed/${resource.link.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/)[1].split('&')[0]}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>

                    ) : resource.type === 'standard' && resource.link ? (
                      <a href={resource.link} target="_blank" rel="noopener noreferrer">
                        {resource.link}
                      </a>
                    ) : resource.embed ? (<div
                      className="resource-embed"
                      dangerouslySetInnerHTML={{ __html: resource.embed }}
                    />
                    ) : null
                    }
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );


};

export default Resources;
