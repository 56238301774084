import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function ProgressChart({ data }) {
  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Chat Messages" stroke="#FF5733" activeDot={{ r: 8 }} />
          <Bar dataKey="Goals Added" stackId="goals" fill="#ffc658" />
          <Bar dataKey="Goals Completed" stackId="goals" fill="#82ca9d" />
          <Bar dataKey="Journal Entries" fill="#fff" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ProgressChart;

