import React from "react";
import Header from './Header';
import Chatbot from "./Chatbot";

const RestrictedPage = () => {
  return (
    <div className="restricted-page">
      <Header />
      <Chatbot />
    </div>
  );
};

export default RestrictedPage;
