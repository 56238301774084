import React from "react";
import "./../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="copyright">© 2023 Aidacura Inc.</div>
      <div className="social-media">
        <a href="https://www.facebook.com/aidacuraltd" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://twitter.com/aidacura" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://www.instagram.com/aidacura/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.linkedin.com/company/aidacura" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
      <div className="crisis-message">
        If you are in a crisis or any other person may be in danger - don't use this site. <Link to="/gethelpnow">These resources</Link> can provide you with immediate help.
      </div>
    </footer>
  );
};

export default Footer;
