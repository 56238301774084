import React, { useState, useEffect } from 'react'
import {
  fetchGoals,
  fetchJournalEntries,
  fetchUserMessagesCount
} from './../helpers/api'
import './../styles/Dashboard.css'
import ProgressChart from './ProgressChart'
import GoalsList from './GoalsList'
import JournalList from './JournalList'
import Header from './Header'
import Footer from './Footer'
import { auth } from './../config/firebaseConfig'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faComment } from '@fortawesome/free-solid-svg-icons'
import ChatbotPreview from './ChatbotPreview'
import Joyride, { STATUS } from 'react-joyride'
import { detectOverflow } from '@popperjs/core'
import UserAchievements from './UserAchievements';

const Dashboard = () => {
  const [chartData, setChartData] = useState([])
  const [timePeriod, setTimePeriod] = useState('30days')
  const [userId, setUserId] = useState(null)
  const [userCreationDate, setUserCreationDate] = useState(null)
  const [joyrideRun, setJoyrideRun] = useState(false)
  
  const journalCount = chartData.reduce((count, data) => count + data['Journal Entries'], 0)
  const goalsCompleted = chartData.reduce((count, data) => count + data['Goals Completed'], 0)
  const chatMessagesCount = chartData.reduce((count, data) => count + data['Chat Messages'], 0)
  const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  const weeklyData = chartData.filter(data => new Date(data.date) >= lastWeek)

  const weeklyJournalCount = weeklyData.reduce((count, data) => count + data['Journal Entries'], 0)
  const weeklyGoalsCompleted = weeklyData.reduce((count, data) => count + data['Goals Completed'], 0)
  const weeklyChatMessagesCount = weeklyData.reduce((count, data) => count + data['Chat Messages'], 0)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid)
        setUserCreationDate(new Date(user.metadata.creationTime))
      } else {
        setUserId(null)
        setUserCreationDate(null)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const preprocessGoalsData = goalsData => {
    const data = {}

    goalsData.forEach(goal => {
      const dateKey = new Date(goal.createdAt).toISOString().slice(0, 10)

      if (!data[dateKey]) {
        data[dateKey] = { added: 0, completed: 0 }
      }

      data[dateKey].added += 1

      if (goal.completed) {
        data[dateKey].completed += 1
      }
    })

    return data
  }

  const preprocessJournalData = journalData => {
    const data = {}

    journalData.forEach(entry => {
      const dateKey = new Date(entry.createdAt).toISOString().slice(0, 10)

      if (!data[dateKey]) {
        data[dateKey] = 0
      }

      data[dateKey] += 1
    })

    return data
  }

  // Helper function to process and combine the fetched data
  const processData = (
    goalsData,
    journalData,
    userMessagesCount,
    startDate
  ) => {
    const currentDate = new Date()
    let data = []

    // Add this line to create a copy of the startDate
    startDate = new Date(startDate)

    while (startDate <= currentDate) {
      const dateKey = startDate.toISOString().slice(0, 10)
      data.push({
        date: dateKey,
        'Chat Messages': userMessagesCount[dateKey] || 0,
        'Goals Added': goalsData[dateKey]?.added || 0,
        'Goals Completed': goalsData[dateKey]?.completed || 0,
        'Journal Entries': journalData[dateKey] || 0
      })
      startDate.setDate(startDate.getDate() + 1)
    }
    return data
  }

  const fetchData = async () => {
    if (!userId || !userCreationDate) {
      return
    }

    const goalsData = await fetchGoals(userId)
    const journalData = await fetchJournalEntries(userId)
    const userMessagesCount = await fetchUserMessagesCount(userId)

    const preprocessedGoalsData = preprocessGoalsData(goalsData)
    const preprocessedJournalData = preprocessJournalData(journalData)

    const allTimeData = processData(
      preprocessedGoalsData,
      preprocessedJournalData,
      userMessagesCount,
      userCreationDate
    )
    const last30DaysData = processData(
      preprocessedGoalsData,
      preprocessedJournalData,
      userMessagesCount,
      new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    )

    return { allTimeData, last30DaysData }
  }

  // Define the tutorial steps
  const tutorialSteps = [
    {
      target: '.dashboard-progress',
      content:
        'Here you can view your recent activity in different areas, such as chat messages, goals, and journal entries.'
    },
    {
      target: '.dashboard-goals',
      content:
        'This section displays your current goals. You can add new goals by clicking the plus icon.'
    },
    {
      target: '.dashboard-journal',
      content:
        'Here is where you can find your recent journal entries. Click the plus icon to add new entries.'
    },
    {
      target: '.dashboard-chatbot',
      content:
        'Interact with the chatbot here for personalized mental health advice and support.'
    }
  ]

  const handleJoyrideCallback = data => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideRun(false);
      localStorage.setItem('dashboardTutorialSeen', 'true');
    }
  };


  useEffect(() => {
    if (userId && userCreationDate) {
      const tutorialSeen = localStorage.getItem('dashboardTutorialSeen');
      if (!tutorialSeen) {
        setJoyrideRun(true);
      } else {
        setJoyrideRun(false);
      }
    }
  }, [userId, userCreationDate]);


  useEffect(() => {
    const updateChartData = async () => {
      const data = await fetchData()
      if (data) {
        if (timePeriod === '30days') {
          setChartData(data.last30DaysData)
        } else {
          setChartData(data.allTimeData)
        }
      }
    }

    if (userId && userCreationDate) {
      updateChartData()
    }
  }, [userId, userCreationDate, timePeriod])

  const handleTimePeriodChange = e => {
    setTimePeriod(e.target.value)
  }

  return (
    <>
      <Header />
      <Joyride
        steps={tutorialSteps}
        run={joyrideRun}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#82c0ba',
            zIndex: 10000
          }
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                padding: 8,
                boundary: 'clippingParents',
                altBoundary: true,
                fallbackPlacements: ['top', 'bottom', 'right', 'left'],
                allowedAutoPlacements: ['top', 'bottom', 'right', 'left'],
                mainAxis: true,
                altAxis: true,
                checkVariation: true,
                detectOverflow: detectOverflow
              }
            }
          ]
        }}
      />
      <h1 className='dash-header'>Dashboard</h1>
      <div className='dashboard'>
        <div className='dashboard-sections'>

          <div className='dashboard-container-top'>
            <div className='dashboard-progress'>
              <h2>Activity</h2>
              <div className='toggle-container'>
                <button
                  className={`toggle-btn ${timePeriod === '30days' ? 'active' : ''
                    }`}
                  value='30days'
                  onClick={handleTimePeriodChange}
                >
                  30 Days
                </button>
                <button
                  className={`toggle-btn ${timePeriod === 'alltime' ? 'active' : ''
                    }`}
                  value='alltime'
                  onClick={handleTimePeriodChange}
                >
                  All Time
                </button>
              </div>
              <ProgressChart key={timePeriod} data={chartData} />
            </div>
            <div className='dashboard-goals'>
              <h2>Goals</h2>
              <Link to='/goalsmanagment' className='manage-goals-button'>
                <FontAwesomeIcon icon={faPlus} />
              </Link>
              <GoalsList userId={userId} />
            </div>
          </div>

          <div className='dashboard-container-bottom'>
            <div className='dashboard-journal'>
              <h2>Journal</h2>
              <Link to='/journal' className='manage-goals-button'>
                <FontAwesomeIcon icon={faPlus} />
              </Link>
              <JournalList userId={userId} />
            </div>
            <div className='dashboard-chatbot'>
              <h2>Chatbot</h2>
              <Link to='/chatbot' className='chatbot-button'>
                <FontAwesomeIcon icon={faComment} />
              </Link>
              <ChatbotPreview />
            </div>
          </div>

          <div className='dashboard-achievements'>
            <UserAchievements
              journalCount={journalCount}
              goalsCompleted={goalsCompleted}
              chatMessagesCount={chatMessagesCount}
              weeklyJournalCount={weeklyJournalCount}
              weeklyGoalsCompleted={weeklyGoalsCompleted}
              weeklyChatMessagesCount={weeklyChatMessagesCount}
            />
          </div>

        </div>
      </div>
      <Footer />
    </>
  );

}

export default Dashboard
