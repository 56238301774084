import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faMedal, faTasks } from '@fortawesome/free-solid-svg-icons';

const UserAchievements = ({
    journalCount,
    goalsCompleted,
    chatMessagesCount,
    weeklyJournalCount,
    weeklyGoalsCompleted,
    weeklyChatMessagesCount,
}) => {
    const badges = {
        'First Journal Entry': journalCount > 0,
        'First Completed Goal': goalsCompleted > 0,
        '10 Journal Entries': journalCount >= 10,
        '10 Completed Goals': goalsCompleted >= 10,
        '50 Chatbot Interactions': chatMessagesCount >= 50,
        '25 Journal Entries': journalCount >= 25,
        '25 Completed Goals': goalsCompleted >= 25,
        '100 Chatbot Interactions': chatMessagesCount >= 100,
        '50 Journal Entries': journalCount >= 50,
        '50 Completed Goals': goalsCompleted >= 50,
        '200 Chatbot Interactions': chatMessagesCount >= 200,
        '100 Journal Entries': journalCount >= 100,
        '100 Completed Goals': goalsCompleted >= 100,
        '500 Chatbot Interactions': chatMessagesCount >= 500,
        'Prolific Writer: 200 Journal Entries': journalCount >= 200,
        'Goal Master: 200 Completed Goals': goalsCompleted >= 200,
        'Chatbot Enthusiast: 1000 Interactions': chatMessagesCount >= 1000,
        'Ultimate User: 500 Journal Entries, 500 Completed Goals, 2000 Chatbot Interactions': journalCount >= 500 && goalsCompleted >= 500 && chatMessagesCount >= 2000,
    };


    const challenges = {
        '5 Journal Entries This Week': weeklyJournalCount >= 5,
        '3 Completed Goals This Week': weeklyGoalsCompleted >= 3,
        'Interact with Chatbot': weeklyChatMessagesCount >= 7,
    };

    return (
        <section className="achievements-section">
            <div className="achievements">
                <div className="badges">
                    <h3><FontAwesomeIcon icon={faMedal} /> Achievements</h3>
                    <div className="badge-container">
                        <ul className="badge-list">
                            {Object.entries(badges).map(([badgeName, earned], index) => (
                                <li key={index} className={`badge-item ${earned ? 'earned' : 'not-earned'}`}>
                                    <FontAwesomeIcon icon={faMedal} className="badge-icon" />
                                    {badgeName}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="challenges">
                    <h3><FontAwesomeIcon icon={faTasks} /> Weekly Challenges</h3>
                    <ul className="challenge-list">
                        {Object.entries(challenges).map(([challengeName, completed], index) => (
                            <li key={index} className={`challenge-item ${completed ? '' : 'not-completed'}`}>
                                <FontAwesomeIcon icon={faTrophy} className="badge-icon" />
                                {challengeName} <span className={`challenge-status ${completed ? 'completed' : 'incomplete'}`}></span>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </section>
    );
};

export default UserAchievements;

