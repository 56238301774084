import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import "./../styles/MainContent.css";
import { FaRobot, FaRegSmileBeam, FaBullseye, FaAngleDoubleDown } from "react-icons/fa";
import Testimonials from "./Testimonials";
import moodTrackingImage from "./../assets/moodTrackingImage.svg";
import chatBot from "./../assets/chatbot2.png";
import goalsLanding from "./../assets/goalsLanding.svg";

const Feature = ({ icon, title, style }) => {
  const [isHovered, setIsHovered] = useState(false); // Add hover state

  const hoverAnimation = useSpring({
    transform: isHovered ? "scale(1.1)" : "scale(1)",
    config: { tension: 400, friction: 20 },
  });

  return (
    <animated.div
      className="feature"
      style={{ ...style, ...hoverAnimation }} // Combine the animation styles
      onMouseEnter={() => setIsHovered(true)} // Update hover state on mouse enter
      onMouseLeave={() => setIsHovered(false)} // Update hover state on mouse leave
    >
      <div className="feature-content">
        {icon}
        <h3>{title}</h3>
      </div>
      <FaAngleDoubleDown className="down-arrow" />
    </animated.div>
  );
};

const MainContent = () => {
  const featuresData = [
    {
      icon: <FaRegSmileBeam size={40} color="white" />,
      title: "Mood Tracking",
    },
    {
      icon: <FaRobot size={40} color="white" />,
      title: "Chatbot",
    },
    {
      icon: <FaBullseye size={40} color="white" />,
      title: "Goal Setting",
    },
  ];

  const featureSectionsData = [
    {
      title: 'AI Powered Chatbot',
      description: 'Our AI-powered chatbot offers personalized mental health advice using natural language processing and machine learning. It provides support and guidance, helping you navigate and maintain your mental well-being.',
      image: chatBot,
      layout: 'normal',
      bgColor: '#494763',
    },
    {
      title: 'Mood Tracking',
      description: 'Track your emotions and gain insights into your mental health patterns. Understand your triggers, develop coping strategies, and make informed decisions about your mental health journey using visualizations and analytics.',
      image: moodTrackingImage,
      layout: 'reversed',
      bgColor: '#171331',
    },
    {
      title: 'Goal Setting',
      description: 'Set achievable goals for your mental health journey and break them into manageable steps. Our platform offers tools to track goals, monitor progress, and receive support from our AI chatbot and community.',
      image: goalsLanding,
      layout: 'normal',
      bgColor: '#494763',
    },
  ];

  return (
    <section className="product-info">
      <h2>Mental Health Tools</h2>
      <div className="features-grid">
        {featuresData.map((feature, index) => (
          <Feature key={index} icon={feature.icon} title={feature.title} />
        ))}
      </div>
      {featureSectionsData.map((section, index) => (
        <div key={index} className={`feature-section ${section.layout}`} style={{ backgroundColor: section.bgColor }}>
          <div className="feature-section-content">
            <h3>{section.title}</h3>
            <p>{section.description}</p>
          </div>
          <img src={section.image} alt={section.title} />
        </div>
      ))}
      <Testimonials />
    </section>
  );
};

export default MainContent;
