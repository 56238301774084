import React from 'react';
import ConversationsList from './ConversationsList';

const ConversationList = ({
  conversations,
  onConversationClick,
  onDeleteConversation,
  handleNewChatClick
}) => {
  return (
    <div className='saved-conversations'>
      <button className='new' onClick={handleNewChatClick}>
        New Chat
      </button>

      <h3>Past Conversations</h3>
      <ConversationsList
        conversations={conversations}
        onConversationClick={onConversationClick}
        onDeleteConversation={onDeleteConversation}
      />
    </div>
  );
};

export default ConversationList;
