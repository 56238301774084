import React from 'react';

const StatusIndicator = ({ isApiOnline }) => {
  return (
    <div className="status-indicator-wrapper">
      <span
        className="status-light"
        style={{ backgroundColor: isApiOnline ? 'green' : 'red' }}
      ></span>
      <span className="status-text">
        {isApiOnline ? 'Aidacura Online' : 'Aidacura Offline'}
      </span>
    </div>
  );
};

export default StatusIndicator;


